<template>
  <div class="nodewrappar" :class="[
    showCon
      ? 'animate__animated animate__zoomIn'
      : 'animate__animated animate__zoomOut',
    `bg${levelId}`,
  ]">
    <div class="stardetails-bg flex" :style="{ paddingBottom: planetInfo.planetStatus == 1 ? '58px' : '58px' }" v-if="showWrap">
      <div class="closeicon" @click="handlerClose(0)"></div>
      <div class="sales-end h5top" v-if="landInfo.status == 0 && type == 2">
        <p>{{ $t("market.desc75") }}</p>
      </div>
      <div class="sales-end endbg1 h5top" v-if="landInfo.status == 2 && type == 2">
        <p>{{ $t("market.desc77") }}</p>
      </div>
      <div class="sales-end endbg2 h5top" v-if="landInfo.status == 3 && type == 2">
        <p>{{ $t("market.desc78") }}</p>
      </div>
      <div class="sales-end endbg3 h5top" v-if="landInfo.status == 1 && type == 2">
        <p>{{ $t("market.desc76") }}</p>
      </div>
      <div class="slider-center h5top">
        <div class="center-top animate__animated animate__zoomIn" :class="{ animate__zoomOut: startId == 2 }"
          v-show="startId == 1" @click="handlerSwitch">
          <star-img :idNameBF="idNameBF" :sphereImg="planetInfo.planetIcon" :starWidth="80" :starHeight="80" />
        </div>
        <div class="center-top animate__animated animate__zoomIn" @click="handlerSwitch" v-show="startId == 2">
          <img :src="planetInfo.landIcon" alt="" />
        </div>
        <div class="center-switch" :class="[`switch${levelId}`]" @click="handlerSwitch"></div>
        <div class="center-bottom animate__animated animate__zoomIn" :class="{ animate__zoomOut: startId == 2 }" :style="{
          marginTop: planetInfo.planetStatus == 1 ? '16px' : '-33px',
        }" v-show="startId == 1">
          <img :src="planetInfo.landImg" alt="" />
        </div>
        <div class="center-bottom animate__animated animate__zoomIn"
          :style="{ marginTop: planetInfo.planetStatus == 1 ? '16px' : '0' }" v-show="startId == 2">
          <star-img :idNameBF="idNameBF" :sphereImg="planetInfo.planetIcon" :starWidth="340" :starHeight="340" />
        </div>
      </div>
      <div class="slider-left animate__animated animate__bounceIn animate__delay-0.5s">
        <div class="left-name" :class="[`nameline${levelId}`]" v-if="planetInfo.planetStatus != 1 && type == 1">
          {{ $t("market.desc18", { n1: $t("home.desc61") }) }}
        </div>
        <div class="left-name" v-else-if="type == 2 && planetInfo.planetStatus != 1">
          {{
            $t("market.desc107", {
              n1: $t("home.desc61"),
            })
          }}
        </div>
        <div class="left-name morename" v-else-if="type == 2">
          {{ $t("market.desc107", { n1: planetInfo.typeName }) }}
        </div>
        <div class="left-name morename" v-else>
          {{ $t("market.desc18", { n1: planetInfo.typeName }) }}
        </div>
        <div class="market-info flex" :class="[`textbg${levelId}`]" v-if="planetInfo.planetStatus != 1">
          <div class="info-desc">
            <div>{{ $t("market.desc12") }}</div>
            <p>{{ planetInfo.track }} - {{ planetInfo.no }}</p>
          </div>
          <div class="info-desc">
            <div>{{ $t("market.desc19") }}</div>
            <p>{{ planetInfo.typeName }}</p>
          </div>
          <div class="info-desc">
            <div>{{ $t("market.desc20") }}</div>
            <p>{{ planetInfo.levelName }}</p>
          </div>
          <div class="info-desc">
            <div>{{ $t("market.desc21") }}</div>
            <p>{{ $t("manage.desc16", { n1: planetInfo.residentCount }) }}</p>
          </div>
          <div class="info-desc">
            <div class="flex">
              {{ $t("market.desc22") }}
              <el-tooltip effect="dark" placement="bottom">
                <template #content>
                  <div v-html="$t('market.desc115', {
                    n1: planetInfo.totalCount,
                    n2:
                      parseFloat(planetInfo.totalCount) -
                      parseFloat(planetInfo.sellCount),
                  })
                    "></div>
                </template>
                <img src="../public/images/new/tips.png" alt="" />
              </el-tooltip>
            </div>
            <p>{{ planetInfo.sellCount }}/{{ planetInfo.totalCount }}</p>
          </div>
          <div class="info-desc">
            <div>{{ $t("market.desc23") }}<span>{{ planetInfo.landPayCoinItemList[0].landPayCoinName}}</span></div>
            <p>
              {{ planetInfo.minPrice }} - {{ planetInfo.maxPrice }}
              <span>{{ planetInfo.landPayCoinItemList[0].landPayCoinName}}</span>
            </p>
          </div>
        </div>
        <div class="marketbig-info" v-else>
          <div class="info-wrap">
            <div class="info-name">{{ $t("manage.desc23") }}</div>
            <div class="info-user">
              {{ $t("land.desc4") }} <span>{{ planetInfo.userName }}</span>
            </div>
            <div class="info-subtitle" v-if="planetInfo.description != ''">
              {{ planetInfo.description }}
            </div>
            <div class="info-subtitle" v-else>{{ $t("manage.desc24") }}</div>
          </div>
          <div class="info-list flex">
            <div class="info-desc">
              <div>{{ $t("market.desc12") }}</div>
              <p>{{ planetInfo.track }} - {{ planetInfo.no }}</p>
            </div>
            <div class="info-desc">
              <div>{{ $t("market.desc19") }}</div>
              <p>{{ planetInfo.typeName }}</p>
            </div>
            <div class="info-desc">
              <div>{{ $t("market.desc20") }}</div>
              <p>{{ planetInfo.levelName }}</p>
            </div>
            <div class="info-desc">
              <div>{{ $t("market.desc21") }}</div>
              <p>{{ $t("manage.desc16", { n1: planetInfo.residentCount }) }}</p>
            </div>
            <div class="info-desc">
              <div class="flex">
                {{ $t("market.desc22") }}
                <el-tooltip effect="dark" placement="bottom">
                  <template #content>
                    <div v-html="$t('market.desc115', {
                      n1: planetInfo.totalCount,
                      n2:
                        parseFloat(planetInfo.totalCount) -
                        parseFloat(planetInfo.sellCount),
                    })
                      "></div>
                  </template>
                  <img src="../public/images/new/tips.png" alt="" />
                </el-tooltip>
              </div>
              <p>{{ planetInfo.sellCount }}/{{ planetInfo.totalCount }}</p>
            </div>
            <div class="info-desc">
              <div>{{ $t("market.desc23") }} <span>{{ planetInfo.landPayCoinItemList[0].landPayCoinName}}</span></div>
              <p>
                {{ planetInfo.minPrice }} - {{ planetInfo.maxPrice }}
                <span>{{ planetInfo.landPayCoinItemList[0].landPayCoinName}}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="slider-center">
        <div class="center-top animate__animated animate__zoomIn" @click="handlerSwitch"
          :class="{ animate__zoomOut: startId == 2 }" v-show="startId == 1">
          <star-img :idNameBF="idNameBF" :sphereImg="planetInfo.planetIcon" :starWidth="140" :starHeight="140" />
        </div>
        <div class="center-top animate__animated animate__zoomIn" @click="handlerSwitch" v-show="startId == 2">
          <img :src="planetInfo.landImg" alt="" />
        </div>
        <div class="center-switch" :class="[`switch${levelId}`]" @click="handlerSwitch"></div>
        <div class="center-bottom animate__animated animate__zoomIn" :class="{ animate__zoomOut: startId == 2 }" :style="{
          marginTop: planetInfo.planetStatus == 1 ? '16px' : '-31px',
        }" v-show="startId == 1">
          <img :src="planetInfo.landImg" alt="" />
        </div>
        <div class="center-bottom animate__animated animate__zoomIn" :style="{
          marginTop: planetInfo.planetStatus == 1 ? '16px' : '-31px',
        }" v-show="startId == 2">
          <star-img :idNameBF="idNameBF" :sphereImg="planetInfo.planetIcon" :starWidth="520" :starHeight="520" />
        </div>
      </div>
      <div class="slider-right animate__animated animate__bounceIn animate__delay-0.5s" v-if="type == 1">
        <div class="right-name">{{ $t("market.desc24") }}</div>
        <div class="right-tab flex">
          <div :class="[payact == 1 ? `tabbgon${levelId}` : `tabbg${levelId}`]" class="flexcenter tabbg1"
            @click="handlerTab(1)">
            {{ $t("node.desc27") }}
          </div>
          <div :class="[payact == 2 ? `tabbgon${levelId}` : `tabbg${levelId}`]" class="flexcenter tabbg1"
            @click="handlerTab(2)">
            {{ $t("node.desc28") }}
          </div>
        </div>
        <div class="right-name">{{ $t("node.desc26") }}</div>
        <div class="right-input flex" v-if="landpaylen == 1">
          <div class="payinfo flex" v-for="(item, i) in planetInfo.landPayCoinItemList" :key="i" v-show="i == 0">
            <img :src="item.landPayCoinIcon" alt="" />
            {{ item.landPayCoinName }}
          </div>
        </div>
        <div class="right-input" v-if="landpaylen == 2">
          <el-dropdown @command="handleCommand">
            <span class="el-dropdown-link">
              <div class="icon-name flex" v-if="payType == 2">
                <div class="inputimg">
                  <img :src="item.landPayCoinIcon" alt="" v-for="item in planetInfo.landPayCoinItemList" />
                </div>
                <div class="inputname flex">
                  <p v-for="(item, i) in planetInfo.landPayCoinItemList">
                    {{ item.landPayCoinName }}<span v-if="i == 0">+</span>
                  </p>
                </div>
              </div>
              <div class="payinfo flex" v-else v-for="(item, i) in planetInfo.landPayCoinItemList" :key="item.id"
                v-show="i == 0">
                <img :src="item.landPayCoinIcon" alt="" />
                {{ item.landPayCoinName }}
              </div>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item :command="1">
                  <div class="payinfo1 flex" v-for="(item, i) in planetInfo.landPayCoinItemList" :key="item.id"
                    v-show="i == 0">
                    <img :src="item.landPayCoinIcon" alt="" />
                    {{ item.landPayCoinName }}
                  </div>
                </el-dropdown-item>
                <el-dropdown-item :command="2">
                  <div class="payinfo1 flex">
                    <div class="inputimg">
                      <img :src="item.landPayCoinIcon" alt="" v-for="item in planetInfo.landPayCoinItemList" />
                    </div>
                    <div class="inputname flex">
                      <p v-for="(item, i) in planetInfo.landPayCoinItemList">
                        {{ item.landPayCoinName }}<span v-if="i == 0">+</span>
                      </p>
                    </div>
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
        <div class="moretips" v-if="payType == 2">
          {{ $t("market.desc40") }}<span>{{ coinTwoRate }}%</span>
        </div>
        <div class="right-name" v-if="payact == 1">
          {{ $t("market.desc25") }}
        </div>
        <div class="right-name" v-else>{{ $t("market.desc43") }}</div>
        <div class="right-input flex" v-if="payact == 2">
          <div class="payinfo flex" v-for="item in planetInfo.balanceItemList">
            {{ item.amount }} {{ item.landPayCoinName }}
          </div>
        </div>
        <div class="right-input flex" v-if="payType == 1 && payact == 1">
          <div class="payinfo flex" v-for="item in planetInfo.balanceItemList" :key="item.id"
            >
            {{ item.amount }} {{ item.landPayCoinName }}
          </div>
        </div>
        <div class="right-input" v-else-if="payType == 2 && payact == 1">
          <div class="payamount flex" v-for="(item, i) in planetInfo.balanceItemList" :key="item.id">
            <p class="flex">
              <img :src="planetInfo.landPayCoinItemList[i].landPayCoinIcon" />{{
                item.landPayCoinName
              }}
            </p>
            <div>{{ item.amount }}</div>
          </div>
        </div>
        <div class="slider-tips" v-if="!planetInfo.isEnoughBalance">
          {{ $t("market.desc28") }}
          <span @click="$router.push(`/asset`)">{{ $t("node.desc42") }}</span>
        </div>
        <div class="right-name">{{ $t("market.desc26") }}</div>
        <div class="right-input flex">
          <input type="text" oninput="value=value.replace(/[^\d]/g,'')" v-model="amount" :placeholder="$t('market.desc27', {
            n1: planetInfo.minPrice,
            n2: planetInfo.maxPrice,
          })
            " @change="onChange" />
          {{ planetInfo.landPayCoinItemList[0].landPayCoinName}}
        </div>
        <div class="paylist" v-if="payType == 2 && payInfo != ''">
          <div class="list-info flex" v-for="item in payInfo.needPayItemList" :key="item.coinId">
            <div>{{ item.landPayCoinName }}{{ $t("market.desc41") }}</div>
            <p>{{ item.amount }} {{ item.landPayCoinName }}</p>
          </div>
        </div>
        <div class="right-name flex">
          <div>{{ $t("market.desc224") }}</div>
          <p>{{ $t("market.desc225") }}{{  planetInfo.mayNum}}</p>
        </div>
        <div class="right-numinput flex">
            <div :style="{color:paynum<2?'#D9D9D9':'#ffffff'}" @click="handlerAdd(1)">-</div>
            <input type="tel" v-model="paynum" 
            @change="onChangeNum"
             onkeyup="this.value=this.value.replace(/\D/g,'')"
             onafterpaste="this.value=this.value.replace(/\D/g,'')" />
            <div :style="{color:paynum<planetInfo.mayNum?'#ffffff':'#D9D9D9'}" @click="handlerAdd(2)">+</div>
        </div>
        <div class="right-stock">
          {{ $t('market.desc226') }}{{ stockNum }}
        </div>
        <div class="slider-check flex" v-if="levelId != undefined">
          <img :src="check
              ? require(`../public/images/land/check${levelId}.png`)
              : require('../public/images/new/checkimg1.png')
            " alt="" @click="handlerCheck" />
          <div @click="showRules = true">
            {{ $t("market.desc29") }}
            <span :class="[`textcolor${levelId}`]">{{
              $t("market.desc42")
            }}</span>
          </div>
        </div>
        <div class="slider-btn greybtn flexcenter" v-if="planetInfo.landStatus == 0">
          {{ $t("market.desc32") }}
        </div>
        <div class="slider-btn flexcenter" :class="[`btnbg${levelId}`]" v-else-if="planetInfo.landStatus == 1"
          @click="showPay">
          {{ $t("market.desc30") }}
        </div>
        <div class="slider-btn greybtn flexcenter" v-else>
          {{ $t("market.desc31") }}
        </div>
      </div>
      <div class="slider-right1 animate__animated animate__bounceIn animate__delay-0.5s"
        :style="{ paddingBottom: landInfo.status == 3 ? '34px' : '150px' }" v-else>
        <div class="sales-end" v-if="landInfo.status == 0">
          <p>{{ $t("market.desc75") }}</p>
        </div>
        <div class="sales-end endbg1" v-if="landInfo.status == 2">
          <p>{{ $t("market.desc77") }}</p>
        </div>
        <div class="sales-end endbg2" v-if="landInfo.status == 3">
          <p>{{ $t("market.desc78") }}</p>
        </div>
        <div class="sales-end endbg3" v-if="landInfo.status == 1">
          <p>{{ $t("market.desc76") }}</p>
        </div>
        <div class="land-list flex">
          <div class="land-list-info">
            <div>{{ $t("market.desc3") }}</div>
            <p v-if="landInfo.status == 0">{{ $t("market.desc75") }}</p>
            <p v-if="landInfo.status == 2">{{ $t("market.desc77") }}</p>
            <p v-if="landInfo.status == 3">{{ $t("market.desc78") }}</p>
            <p v-if="landInfo.status == 1">{{ $t("market.desc76") }}</p>
          </div>
          <div class="land-list-info">
            <div>{{ $t("market.desc69") }}</div>
            <p>{{ landInfo.investTotalAmount }} {{ planetInfo.landPayCoinItemList[0].landPayCoinName}}</p>
          </div>
          <div class="land-list-info" v-if="landInfo.versionType==0">
            <div>{{ $t("market.desc79") }}</div>
            <p v-if="landInfo.status == 0">
              {{ $t("pool.desc13", { n1: "--" }) }}
            </p>
            <p v-else>{{ $t("pool.desc13", { n1: landInfo.earningsDays }) }}</p>
          </div>
          <div class="land-list-info" v-if="landInfo.versionType==1">
            <div>{{ $t("market.desc228") }}</div>
            <p v-if="landInfo.status == 0">
              {{ $t("pool.desc13", { n1: "--" }) }}
            </p>
            <p v-else>{{ $t("pool.desc13", { n1: landInfo.freeDay }) }}</p>
          </div>
          <div class="land-list-info">
            <div>{{ $t("market.desc108") }}</div>
            <p>{{ common.formatDate2(landInfo.effectiveTime) }}</p>
          </div>
          <div class="land-list-info">
            <div>{{ $t("market.desc109") }}</div>
            <p v-if="landInfo.status == 0">-- {{ landInfo.earningsCoinName }}</p>
            <p v-else>{{ landInfo.totalEarningAmount }} {{ landInfo.earningsCoinName }}</p>
          </div>
          <div class="land-list-info" v-if="landInfo.status == 3">
            <div>{{ $t("market.desc113") }}</div>
            <p>{{ common.formatDate2(landInfo.disinvestTime) }}</p>
          </div>
          <div class="land-list-info" v-else-if="landInfo.status == 2">
            <div>{{ $t("market.desc114") }}</div>
            <p>{{ common.formatDate2(landInfo.finishTime) }}</p>
          </div>
          <div class="land-list-info" v-else-if="landInfo.versionType==0">
            <div>{{ $t("market.desc110") }}</div>
            <p>{{ landInfo.landPriceMin }} - {{ landInfo.landPriceMax }} {{ planetInfo.landPayCoinItemList[0].landPayCoinName}}</p>
          </div>
          <div class="land-list-info" v-if="landInfo.versionType==0">
            <div class="flex">{{ $t('market.desc180') }} <img src="../public/images/new/tips.png" alt="" @click="handlerRate" /></div>
            <p>{{ common.getnum(landInfo.rate * 100) }}%</p>
          </div>
        </div>
        <div class="land-btn flexcenter" @click="handlerShowDiv" v-if="landInfo.status == 3">
          {{ $t("market.desc111") }}
        </div>
      </div>
    </div>
    <el-dialog v-model="showpsd" :append-to-body="true">
      <div class="modal-con">
        <img src="../public/images/new/closeicon.png" alt="" class="close" @click="showpsd = false" />
        <div class="modal-title">{{ $t("home.desc7") }}</div>
        <div class="modal-desc">{{ $t("node.desc53") }}</div>
        <div class="modal-input">
          <psdinput @finish="finish" @finish1="finish1" v-if="showpsd" ref="PsdInput" />
        </div>

        <div class="modal-btncon flexcenter" @click="handlerPay" v-loading="loading">
          {{ $t("home.desc20") }}
        </div>
        <div class="forgottitle" @click="handlerLogpsd">{{ $t('sign.desc72') }}</div>
      </div>
    </el-dialog>
    <van-popup v-model:show="showRules" round>
      <Article :type="3" @closeModal="closeModal"></Article>
    </van-popup>
    <el-dialog v-model="showSuccess" :append-to-body="true">
      <div class="modal-con">
        <img src="../public/images/new/closeicon.png" alt="" class="close" @click="handlerClose(1)" />
        <div class="modal-title">{{ $t("market.desc38") }}</div>
        <div class="modal-desc">{{ $t("market.desc39") }}</div>
        <div class="modal-btn flex">
          <div class="flexcenter" @click="handlerClose(1)">
            {{ $t("market.desc36") }}
          </div>
          <div class="flexcenter" @click="$router.push('/planet')">
            {{ $t("market.desc37") }}
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 撤资详情 -->
    <el-dialog v-model="showDivestment" :append-to-body="true">
      <div class="modal-con">
        <img src="../public/images/new/closeicon.png" alt="" class="close" @click="showDivestment = false" />
        <div class="modal-title">{{ $t("market.desc111") }}</div>
        <div class="divestment-list">
          <div class="divestment-info flex">
            <div>{{ $t("market.desc112") }}</div>
            <p>{{ $t("market.desc96") }}</p>
          </div>
          <div class="divestment-info flex">
            <div>{{ $t("market.desc86") }}</div>
            <p>{{ divestmentInfo.track }} - {{ divestmentInfo.no }}</p>
          </div>
          <div class="divestment-info flex">
            <div>{{ $t("market.desc87") }}</div>
            <p>{{ divestmentInfo.disinvestAmount }}</p>
          </div>
          <div class="divestment-info flex">
            <div>
              {{
                $t("market.desc88", {
                  n1: parseFloat(divestmentInfo.disinvestFeeRate) * 100,
                })
              }}
            </div>
            <p>
              {{ divestmentInfo.disinvestFee }} {{ divestmentInfo.coinIdName }}
            </p>
          </div>
          <div class="divestment-info flex">
            <div>{{ $t("market.desc89") }}</div>
            <p>
              {{ divestmentInfo.arriveAmount }} {{ divestmentInfo.coinIdName }}
            </p>
          </div>
          <div class="divestment-info flex">
            <div>{{ $t("market.desc113") }}</div>
            <p>{{ common.formatDate(divestmentInfo.disinvestTime) }}</p>
          </div>
        </div>
        <div class="modal-tipsname flex">
          <img src="../public/images/land/tag3.png" alt="" />{{
            $t("market.desc95")
          }}
        </div>
        <div class="modal-tips1">
          <div>{{ $t("market.desc90") }}</div>
          <div>
            {{
              $t("market.desc91", {
                n1: parseFloat(divestmentInfo.disinvestFeeRate) * 100,
              })
            }}
          </div>
          <div>{{ $t("market.desc92") }}</div>
        </div>
      </div>
    </el-dialog>
    <!-- 资金密码 -->
    <el-dialog v-model="showTrade" :append-to-body="true">
      <div class="modal-con">
        <img src="../public/images/new/closeicon.png" alt="" class="close" @click="showTrade = false" />
        <div class="modal-title">{{ $t("node.desc45") }}</div>
        <div class="modal-desc">{{ $t("node.desc46") }}</div>
        <div class="modal-btn flex">
          <div class="flexcenter" @click="showTrade = false">
            {{ $t("node.desc47") }}
          </div>
          <div class="flexcenter" @click="handlerSubmit(1)">
            {{ $t("node.desc48") }}
          </div>
        </div>
        <div class="forgottitle">{{ $t('sign.desc72') }}</div>
      </div>
    </el-dialog>
    <!-- 谷歌密码 -->
    <el-dialog v-model="showGoogle" :append-to-body="true">
      <div class="modal-con">
        <img src="../public/images/new/closeicon.png" alt="" class="close" @click="showGoogle = false" />
        <div class="modal-title">{{ $t("home.desc64") }}</div>
        <div class="modal-desc">{{ $t("home.desc65") }}</div>
        <div class="modal-btn flex">
          <div class="flexcenter" @click="showGoogle = false">
            {{ $t("node.desc47") }}
          </div>
          <div class="flexcenter" @click="handlerSubmit(2)">
            {{ $t("node.desc48") }}
          </div>
        </div>
      </div>
    </el-dialog>
     <!-- 比例说明 -->
     <van-popup v-model:show="showRate" :close-on-click-overlay="false">
      <div class="modal-con ratecon">
        <img src="../public/images/new/closeicon.png" alt="" class="close" @click="showRate = false" />
        <div class="modal-title">{{ $t("market.desc177") }}</div>
        <div class="rate-list">
          <div>{{ $t('market.desc178') }}</div>
          <div>{{ $t('market.desc179') }}</div>
        </div>
        <div class="rate-table">
          <div class="table-thead flex">
            <div class="flexcenter">{{ $t('market.desc180') }}</div>
            <div class="flexcenter">{{ $t('market.desc181') }}</div>
            <div class="flexcenter">{{ $t('market.desc182') }}</div>
            <div class="flexcenter">{{ $t('market.desc183') }}</div>
          </div>
          <div class="table-tbody">
            <div class="tbody-info flex" v-for="item in rateList" :key="item.id">
              <div>{{ item.planetLandSellRatio * 100 }} %</div>
              <div>{{ item.landReleaseRatio * 100 }} %</div>
              <div>{{ $t('pool.desc13', { n1: item.releaseDay }) }} </div>
              <div>{{ item.planetReleaseRatio * 100 }} %</div>
            </div>
          </div>
        </div>
        <div class="ratebtn flexcenter" @click="showRate = false">{{ $t('assetbag.desc71') }}</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import starImg from "./starImg.vue";
import Article from "../views/user/article.vue";
import psdinput from "./psdinput.vue";
import bus from "@/utils/bus";
export default {
  props: ["planetId", "levelId", "type", "landId"],
  components: {
    starImg,
    Article,
    psdinput,
  },
  data() {
    return {
      paynum:1,
      showRate:false,
      showTrade: false,
      showDivestment: false,
      showpsd: false,
      showCon: true,
      planetInfo: {},
      idNameBF: "",
      payact: 1,
      check: true,
      showRules: false,
      amount: "",
      password: "",
      loading: false,
      payType: 1,
      userInfo: {},
      showSuccess: false,
      landpaylen: 1,
      payInfo: {},
      showWrap: false,
      landInfo: {},
      startId: 1,
      divestmentInfo: {},
      coinTwoRate: 0,
      showGoogle: false,
      rateList: [],
      stockNum:0
    };
  },
  created() {
    this.getinfo();
    if (localStorage.getItem("token") != null) {
      this.$post(this.URL.member.info, {}).then((res) => {
        if (res.code == 0) {
          this.userInfo = res.data;
        }
      });
    }
    if (this.type == 2) {
      this.$post(this.URL.market.detail, {
        id: this.landId,
      }).then((res) => {
        if (res.code == 0) {
          this.landInfo = res.data;
        }
      });
    }
    // 比例说明
    this.$post(this.URL.planet.config, {}).then(res => {
      if (res.code == 0) {
        this.rateList = res.data;
      }
    })
  },
  methods: {
    onChangeNum(val) {
      if(this.planetInfo.mayNum==0){
        this.paynum=0;
        return;
      }
      let num=this.stockNum;
    
      if(num>this.planetInfo.mayNum){
        num=this.planetInfo.mayNum
      }
      if(this.paynum<1){
        this.paynum=1;
      }else if(this.paynum>num){
        this.paynum=num
      }
    },
    handlerAdd(i) {
      if(this.planetInfo.mayNum==0){
        return;
      }
      let num=this.stockNum;
      if(num>this.planetInfo.mayNum){
        num=this.planetInfo.mayNum
      }
      if(i==1){
        this.paynum<2?this.paynum=1:this.paynum--
      }else{
        this.paynum>=num?this.paynum=num:this.paynum++;
      }
    },
    handlerRate() {
      if (localStorage.getItem('ismobile') == 1) {
        this.$router.push('/market/rules');
        return;
      }
      this.showRate = true
    },
    handlerLogpsd() {
      this.showTrade = false;
      this.showGoogle=false;
      this.handlerClose(0);
      if (localStorage.getItem('ismobile') == 1) {
          this.$router.push('/user/info?id=2')
      } else {
        bus.emit("hadleropen", 1);
      }
    },
    handlerSubmit(i) {
      this.showTrade = false;
      this.showGoogle = false;
      this.handlerClose(0);
      if (localStorage.getItem('ismobile') == 1) {
        if (i == 1) {
          this.$router.push('/user/info?id=2')
        } else {
          this.$router.push('/user/info?id=4')
        }
      } else {

        bus.emit("hadleropen", i);
      }


      return;
    },
    handlerShowDiv() {
      this.$post(this.URL.market.disinvestInfo, {
        landId: this.landId,
      }).then((res) => {
        if (res.code == 0) {
          this.divestmentInfo = res.data;
          this.showDivestment = true;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    handlerSwitch() {
      this.startId == 1 ? (this.startId = 2) : (this.startId = 1);
    },
    getinfo() {
      this.$post(this.URL.market.info, {
        planetId: this.planetId,
        accountType: this.payact,
        payType: this.payType,
      }).then((res) => {
        if (res.code == 0) {
          this.planetInfo = res.data;
          if (this.planetInfo.landPayCoinItemList.length > 1) {
            let coinTwoRate = parseFloat(this.planetInfo.coinTwoRate) * 100;
            this.coinTwoRate = coinTwoRate.toFixed(0);
          }
          this.showWrap = true;
          this.landpaylen = res.data.landPayCoinItemList.length;
          this.stockNum=res.data.totalCount - res.data.sellCount;
          if(this.planetInfo.mayNum == 0){
            this.paynum=0;
          }
        } else {
          this.$message.error(res.message);
        }
      });
    },
    handlerTab(i) {
      this.amount = "";
      this.payact = i;
      if (i == 2) {
        this.payType = 1;
        this.landpaylen = 1;
      } else {
        if (this.planetInfo.landPayCoinItemList.length > 1) {
          this.landpaylen = 2;
          this.payType = 2;
        }
      }

      this.getinfo();
    },
    onChange(val) {
      if (this.amount < parseFloat(this.planetInfo.minPrice)) {
        this.$message.error(
          this.$t("market.desc34", { n1: this.planetInfo.minPrice })
        );
        this.amount = parseFloat(this.planetInfo.minPrice);
        this.payInfo = {};
      } else if (this.amount > parseFloat(this.planetInfo.maxPrice)) {
        this.$message.error(
          this.$t("market.desc35", { n1: this.planetInfo.maxPrice })
        );
        this.amount = parseFloat(this.planetInfo.maxPrice);
        this.payInfo = {};
      } else if (this.payType == 2) {
        this.$post(this.URL.market.paycal, {
          planetId: this.planetId,
          accountType: 1,
          payType: 2,
          payAmount: this.amount,
          type: 1,
        }).then((res) => {
          if (res.code == 0) {
            this.payInfo = res.data;
          }
        });
      }
    },
    finish(val) {
      this.password = val;
    },
    finish1(val) {
      if (val == 0) {
        this.password = "";
      }
    },
    handlerPay() {
      if (this.password == "") {
        this.$message.error(this.$t("planet.desc17"));
        return;
      }
      let payItemJsonArray = [];
      if (this.payType == 1) {
        payItemJsonArray = [
          {
            amount: this.amount,
            coinId: this.planetInfo.landPayCoinItemList[0].landPayCoinId,
          },
        ];
      } else {
        this.payInfo.needPayItemList.forEach((e) => {
          payItemJsonArray.push({
            amount: e.amount,
            coinId: e.coinId,
          });
        });
      }
      if(this.loading){
        return;
      }
      this.loading = true;
      this.$post(this.URL.market.buy, {
        planetId: this.planetId,
        accountType: this.payact,
        payType: this.payType,
        password:
          this.userInfo.sourceType == 2 || this.userInfo.sourceType == 3
            ? this.password
            : this.common.mdpassword(this.password),
        amount: this.amount,
        payItemJsonArray: JSON.stringify(payItemJsonArray),
        buyNum:this.paynum
      }).then((res) => {
       
        if (res.code == 0) {
          this.showpsd = false;
          this.showSuccess = true;
        } else {
          this.loading = false;
          this.password = "";
          this.$refs.PsdInput.reset();
          this.$message.error(res.message);
        }
      });
    },
    showPay() {
      if (this.amount == "") {
        this.$message.error(this.$t("market.desc33"));
        return;
      }
      if(this.planetInfo.mayNum==0){
        this.$message.error(this.$t("market.desc227"));
        return;
      }
      // if (!this.userInfo.hasBindGoogleAuth && !this.userInfo.hasSetPassword) {
      //   this.showGoogle = true;
      //   return;
      // }
      if (!this.userInfo.hasSetPassword) {
        this.showTrade = true;
        return;
      }
      if (this.amount < parseFloat(this.planetInfo.minPrice)) {
        this.$message.error(
          this.$t("market.desc34", { n1: this.planetInfo.minPrice })
        );
        return;
      }
      if (this.amount > parseFloat(this.planetInfo.maxPrice)) {
        this.$message.error(
          this.$t("market.desc35", { n1: this.planetInfo.maxPrice })
        );
        return;
      }
      if (!this.planetInfo.isEnoughBalance && this.payact == 1) {
        this.$message.error(this.$t("market.desc28"));
        return;
      }


      this.showpsd = true;
      this.password = "";
      this.loading = false;
    },
    closeModal() {
      this.showRules = false;
    },
    handlerCheck() {
      this.check ? (this.check = false) : (this.check = true);
    },
    handlerClose(i) {
      this.showSuccess = false;
      this.showCon = false;
      setTimeout(() => {
        this.$emit("handlerClose", i);
      }, 500);
    },
    handleCommand(val) {
      this.payType = val;
    },
  },
};
</script>

<style lang="less" scoped>
.nodewrappar {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 997;
  background: url("../public/images/land/bigbg1.png") top no-repeat;
  background-size: cover;
  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 0px; //修改滚动条宽度
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #666666;
  }

  .stardetails-bg {
    position: relative;
    width: 1294px;
    height: fit-content;
    margin: 117px auto 0;
    background: url("../public/images/land/samllbg1.png") top no-repeat;
    background-size: 100% 96%;

    .closeicon {
      position: absolute;
      top: 18px;
      left: 0;
      width: 44px;
      height: 44px;
      background: url("../public/images/new/close.png") center no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
      z-index: 56;
    }

    .slider-left {
      flex: 0 0 471px;
      margin: 0 -110px 0 23px;

      .left-name {
        width: 360px;
        position: relative;
        margin: 120px 0 0;
        font-size: 24px;
        color: #fff;

        &::after {
          content: "";
          position: absolute;
          bottom: -28px;
          left: 73px;
          width: 472px;
          height: 31px;
          background: url("../public/images/land/line1.png") center no-repeat;
          background-size: 100% 100%;
        }
      }

      .nameline2 {
        &::after {
          background: url("../public/images/land/line2.png") center no-repeat;
          background-size: 100% 100%;
        }
      }

      .nameline3 {
        &::after {
          background: url("../public/images/land/line3.png") center no-repeat;
          background-size: 100% 100%;
        }
      }

      .nameline4 {
        &::after {
          background: url("../public/images/land/line4.png") center no-repeat;
          background-size: 100% 100%;
        }
      }

      .nameline5 {
        &::after {
          background: url("../public/images/land/line5.png") center no-repeat;
          background-size: 100% 100%;
        }
      }

      .nameline6 {
        &::after {
          background: url("../public/images/land/line6.png") center no-repeat;
          background-size: 100% 100%;
        }
      }

      .nameline7 {
        &::after {
          background: url("../public/images/land/line7.png") center no-repeat;
          background-size: 100% 100%;
        }
      }

      .morename {
        font-size: 18px;

        &::after {
          background: none;
        }
      }

      .market-info {
        margin: 90px 0 0;
        width: 431px;
        padding: 54px 0 56px 24px;
        background: url("../public/images/land/textbg1.png") center no-repeat;
        background-size: 100% 100%;
        flex-wrap: wrap;

        .info-desc {
          flex: 0 0 144px;
          font-size: 12px;
          color: rgba(255, 255, 255, 0.8);
          line-height: 12px;

          &:nth-child(-n + 4) {
            margin-bottom: 32px;
          }

          &:last-child {
            flex: 0 0 125px;
          }

          div {
            img {
              width: 12px;
              height: 12px;
              margin-left: 4px;
              cursor: pointer;
            }

            span {
              display: none;
            }
          }

          p {
            font-size: 20px;
            margin-top: 13px;
            color: #fff;
            line-height: 20px;
          }
        }
      }

      .textbg2 {
        background: url("../public/images/land/textbg2.png") center no-repeat;
        background-size: 100% 100%;
      }

      .textbg3 {
        background: url("../public/images/land/textbg3.png") center no-repeat;
        background-size: 100% 100%;
      }

      .textbg4 {
        background: url("../public/images/land/textbg4.png") center no-repeat;
        background-size: 100% 100%;
      }

      .textbg5 {
        background: url("../public/images/land/textbg5.png") center no-repeat;
        background-size: 100% 100%;
      }

      .textbg6 {
        background: url("../public/images/land/textbg6.png") center no-repeat;
        background-size: 100% 100%;
      }

      .textbg7 {
        background: url("../public/images/land/textbg7.png") center no-repeat;
        background-size: 100% 100%;
      }

      .marketbig-info {
        margin-top: 8px;
        width: 435px;
        height: 516px;
        background: url("../public/images/land/leftbg.png") center no-repeat;
        background-size: 100% 100%;

        .info-name {
          padding: 20px 0 0 24px;
          font-size: 16px;
          color: #d2fb71;
          line-height: 40px;
        }

        .info-user {
          margin: 11px 24px 16px;
          font-size: 14px;
          line-height: 14px;
          color: rgba(255, 255, 255, 0.5);

          span {
            color: #fff;
          }
        }

        .info-subtitle {
          width: 308px;
          padding: 19px 24px 0;
          font-size: 14px;
          height: 147px;
          color: rgba(255, 255, 255, 0.8);
        }

        .info-list {
          margin-top: 46px;
          width: 431px;
          flex-wrap: wrap;
          padding: 0 24px;

          .info-desc {
            flex: 0 0 144px;
            font-size: 12px;
            color: rgba(255, 255, 255, 0.8);
            line-height: 12px;

            &:nth-child(-n + 4) {
              margin-bottom: 20px;
            }

            &:last-child {
              flex: 0 0 125px;
            }

            div {
              img {
                width: 12px;
                height: 12px;
                margin-left: 4px;
                cursor: pointer;
              }

              span {
                display: none;
              }
            }

            p {
              font-size: 20px;
              margin-top: 13px;
              color: #fff;
              line-height: 20px;
            }
          }
        }
      }
    }

    .slider-center {
      position: relative;
      flex: 0 0 520px;
      width: 520px;
      min-height: 520px;
      margin-top: 70px;

      .center-top {
        width: 140px;
        height: 140px;
        margin: 0 auto;

        img {
          width: 140px;
          height: 140px;
        }
      }

      .center-switch {
        position: absolute;
        top: 140px;
        left: 50%;
        width: 44px;
        height: 44px;
        margin-left: -22px;
        background: url("../public/images/land/switch1.png") center no-repeat;
        background-size: 100% 100%;
        cursor: pointer;
        z-index: 6;
      }

      .switch2 {
        background: url("../public/images/land/switch2.png") center no-repeat;
        background-size: 100% 100%;
      }

      .switch3 {
        background: url("../public/images/land/switch3.png") center no-repeat;
        background-size: 100% 100%;
      }

      .switch4 {
        background: url("../public/images/land/switch4.png") center no-repeat;
        background-size: 100% 100%;
      }

      .switch5 {
        background: url("../public/images/land/switch5.png") center no-repeat;
        background-size: 100% 100%;
      }

      .switch6 {
        background: url("../public/images/land/switch6.png") center no-repeat;
        background-size: 100% 100%;
      }

      .switch7 {
        background: url("../public/images/land/switch7.png") center no-repeat;
        background-size: 100% 100%;
      }

      .center-bottom {
        width: 520px;
        height: 520px;
        margin-top: -41px;

        img {
          width: 520px;
          height: 520px;
        }
      }
    }

    .slider-right {
      flex: 0 0 320px;
      margin: 63px 0 0 34px;

      .right-name {
        margin-bottom: 16px;
        font-size: 16px;
        color: #fff;
        line-height: 18px;
        justify-content: space-between;
        p{
          color: rgba(255, 255, 255, 0.4);
        }
      }
      .right-numinput{
        margin: 16px 0;
        border: 1px solid rgba(255, 255, 255, 0.20);
        box-sizing: border-box;
        div{
          flex: 0 0 52px;
          width: 52px;
          height: 52px;
          font-size: 26px;
          color: #D9D9D9;
          text-align: center;
          line-height: 52px;
          cursor: pointer;
        }
        input{
          flex: 1;
          height: 52px;
          font-size: 16px;
          color: #FFFFFF;
          text-align: center;
          border-left: 1px solid rgba(255, 255, 255, 0.2);
          border-right: 1px solid rgba(255, 255, 255, 0.2);
        }
      }
      .right-stock{
        text-align: right;
        font-size: 16px;
        color: rgba(255, 255, 255, 0.4);
      }

      .right-tab {
        margin-bottom: 20px;
        justify-content: space-between;

        div {
          flex: 0 0 154px;
          width: 154px;
          height: 56px;
          background: url("../public/images/land/textbtn1.png") center no-repeat;
          background-size: 100% 100%;
          font-size: 15px;
          color: #ffffff;
          cursor: pointer;

          &:hover {
            background: url("../public/images/land/textbtn1_on.png") center no-repeat;
            background-size: 100% 100%;
            color: #000000;
          }
        }

        .tabbgon1 {
          background: url("../public/images/land/textbtn1_on.png") center no-repeat;
          background-size: 100% 100%;
          color: #000000;
        }

        .tabbg2 {
          background: url("../public/images/land/textbtn2.png") center no-repeat;
          background-size: 100% 100%;

          &:hover {
            background: url("../public/images/land/textbtn2_on.png") center no-repeat !important;
            background-size: 100% 100%;
          }
        }

        .tabbgon2 {
          background: url("../public/images/land/textbtn2_on.png") center no-repeat;
          background-size: 100% 100%;
          color: #000000;

          &:hover {
            background: url("../public/images/land/textbtn2_on.png") center no-repeat !important;
            background-size: 100% 100%;
          }
        }

        .tabbg3 {
          background: url("../public/images/land/textbtn3.png") center no-repeat;
          background-size: 100% 100%;

          &:hover {
            background: url("../public/images/land/textbtn3_on.png") center no-repeat !important;
            background-size: 100% 100%;
          }
        }

        .tabbgon3 {
          background: url("../public/images/land/textbtn3_on.png") center no-repeat;
          background-size: 100% 100%;
          color: #000000;

          &:hover {
            background: url("../public/images/land/textbtn3_on.png") center no-repeat !important;
            background-size: 100% 100%;
          }
        }

        .tabbg4 {
          background: url("../public/images/land/textbtn4.png") center no-repeat;
          background-size: 100% 100%;

          &:hover {
            background: url("../public/images/land/textbtn4_on.png") center no-repeat !important;
            background-size: 100% 100%;
          }
        }

        .tabbgon4 {
          background: url("../public/images/land/textbtn4_on.png") center no-repeat;
          background-size: 100% 100%;
          color: #000000;

          &:hover {
            background: url("../public/images/land/textbtn4_on.png") center no-repeat !important;
            background-size: 100% 100%;
          }
        }

        .tabbg5 {
          background: url("../public/images/land/textbtn5.png") center no-repeat;
          background-size: 100% 100%;

          &:hover {
            background: url("../public/images/land/textbtn5_on.png") center no-repeat !important;
            background-size: 100% 100%;
          }
        }

        .tabbgon5 {
          background: url("../public/images/land/textbtn5_on.png") center no-repeat;
          background-size: 100% 100%;
          color: #000000;

          &:hover {
            background: url("../public/images/land/textbtn5_on.png") center no-repeat !important;
            background-size: 100% 100%;
          }
        }

        .tabbg6 {
          background: url("../public/images/land/textbtn6.png") center no-repeat;
          background-size: 100% 100%;

          &:hover {
            background: url("../public/images/land/textbtn6_on.png") center no-repeat !important;
            background-size: 100% 100%;
          }
        }

        .tabbgon6 {
          background: url("../public/images/land/textbtn6_on.png") center no-repeat;
          background-size: 100% 100%;
          color: #000000;

          &:hover {
            background: url("../public/images/land/textbtn6_on.png") center no-repeat !important;
            background-size: 100% 100%;
          }
        }

        .tabbg7 {
          background: url("../public/images/land/textbtn7.png") center no-repeat;
          background-size: 100% 100%;

          &:hover {
            background: url("../public/images/land/textbtn7_on.png") center no-repeat !important;
            background-size: 100% 100%;
          }
        }

        .tabbgon7 {
          background: url("../public/images/land/textbtn7_on.png") center no-repeat;
          background-size: 100% 100%;
          color: #000000;

          &:hover {
            background: url("../public/images/land/textbtn7_on.png") center no-repeat !important;
            background-size: 100% 100%;
          }
        }
        // .tabbgon9 {
        //   background: url("../public/images/land/textbtn1.png") center no-repeat;
        //   background-size: 100% 100%;
        //   color: #000000;

        //   &:hover {
        //     background: url("../public/images/land/textbtn1_on.png") center no-repeat !important;
        //     background-size: 100% 100%;
        //   }
        // }
      }

      .right-input {
        margin-bottom: 20px;
        padding: 12px 16px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        box-sizing: border-box;
        font-size: 16px;
        color: #ffffff;
        line-height: 28px;

        .payinfo {
          flex: 0 0 100%;

          img {
            width: 28px;
            height: 28px;
            margin-right: 8px;
          }
        }

        input {
          flex: 1;
          height: 28px;
          font-size: 16px;
          color: #fff;

          &::placeholder {
            color: rgba(255, 255, 255, 0.4);
          }
        }

        .payamount {
          padding: 12px 0;
          border-bottom: 1px solid rgba(255, 255, 255, 0.1);
          box-sizing: border-box;

          &:last-child {
            border-bottom: 0;
            padding-bottom: 0;
          }

          img {
            width: 20px;
            height: 20px;
            margin-right: 8px;
          }

          p {
            line-height: 20px;
          }

          div {
            flex: 1;
            font-size: 14px;
            line-height: 20px;
            text-align: right;
          }
        }
      }

      .moretips {
        margin-bottom: 20px;
        font-size: 12px;
        color: #e3e3e3;

        span {
          color: #c9fa5b;
          padding-left: 4px;
        }
      }

      .slider-tips {
        margin-bottom: 20px;
        font-size: 14px;
        color: #f8a430;
        line-height: 16px;

        span {
          padding-left: 5px;
          color: #c9fa5b;
          cursor: pointer;
        }
      }

      .paylist {
        margin-bottom: 20px;

        .list-info {
          justify-content: space-between;
          font-size: 14px;
          color: rgba(255, 255, 255, 0.8);

          &:last-child {
            margin-top: 10px;
          }

          p {
            color: #fff;
          }
        }
      }

      .slider-check {
        padding-top: 12px;
        font-size: 13px;
        color: rgba(255, 255, 255, 0.6);
        line-height: 16px;

        img {
          width: 16px;
          height: 16px;
          margin-right: 8px;
          cursor: pointer;
        }

        div {
          span {
            cursor: pointer;
          }

          .textcolor1 {
            color: #ffeba5;
          }

          .textcolor2 {
            color: #ffe279;
          }

          .textcolor3 {
            color: #c9fa5b;
          }

          .textcolor4 {
            color: #f98f2c;
          }

          .textcolor5 {
            color: #f599f6;
          }

          .textcolor6 {
            color: #40b1f0;
          }

          .textcolor7 {
            color: #30cdff;
          }
        }
      }

      .slider-btn {
        margin-top: 18px;
        width: 100%;
        height: 56px;
        background: url("../public/images/land/btnbg2.png") center no-repeat;
        background-size: 100% 100%;
        font-size: 16px;
        color: #000000;
        font-weight: 600;
        cursor: pointer;
      }

      .btnbg2 {
        background: url("../public/images/land/btnbg3.png") center no-repeat;
        background-size: 100% 100%;
      }

      .btnbg3 {
        background: url("../public/images/land/btnbg4.png") center no-repeat;
        background-size: 100% 100%;
      }

      .btnbg4 {
        background: url("../public/images/land/btnbg5.png") center no-repeat;
        background-size: 100% 100%;
      }

      .btnbg5 {
        background: url("../public/images/land/btnbg6.png") center no-repeat;
        background-size: 100% 100%;
      }

      .btnbg6 {
        background: url("../public/images/land/btnbg7.png") center no-repeat;
        background-size: 100% 100%;
      }

      .btnbg7 {
        background: url("../public/images/land/btnbg8.png") center no-repeat;
        background-size: 100% 100%;
      }

      .greybtn {
        background: url("../public/images/land/btnbg1.png") center no-repeat;
        background-size: 100% 100%;
      }
    }

    .slider-right1 {
      flex: 0 0 354px;
      margin-top: 81px;
      padding: 40px 32px 0;
      background: url("../public/images/land/rightbg.png") top no-repeat;
      background-size: auto;

      .land-list {
        padding: 40px 0 0;
        flex-wrap: wrap;

        .land-list-info {
          margin-top: 32px;
          flex: 0 0 50%;
          font-size: 12px;
          color: rgba(255, 255, 255, 0.8);
          line-height: 12px;

          &:nth-child(-n + 2) {
            margin-top: 0;
          }
          div{
            img{
              width: 12px;
              height: 12px;
              cursor: pointer;
              margin-left: 4px;
            }
          }
          p {
            margin-top: 13px;
            font-size: 20px;
            color: #fff;
            line-height: 20px;
          }
        }
      }

      .land-btn {
        margin-top: 106px;
        width: 100%;
        height: 56px;
        background: url("../public/images/land/btnbg4.png") center no-repeat;
        background-size: 100% 100%;
        font-size: 16px;
        color: #000000;
        font-weight: 600;
        cursor: pointer;
      }
    }
  }
}

.bg2 {
  background: url("../public/images/land/bigbg2.png") top no-repeat;
  background-size: cover;
}

.bg3 {
  background: url("../public/images/land/bigbg3.png") top no-repeat;
  background-size: cover;
}

.bg4 {
  background: url("../public/images/land/bigbg4.png") top no-repeat;
  background-size: cover;
}

.bg5 {
  background: url("../public/images/land/bigbg5.png") top no-repeat;
  background-size: cover;
}

.bg6 {
  background: url("../public/images/land/bigbg6.png") top no-repeat;
  background-size: cover;
}

.bg7 {
  background: url("../public/images/land/bigbg7.png") top no-repeat;
  background-size: cover;
}

::v-deep .el-dropdown {
  width: 100%;
  border: 0 !important;

  .el-dropdown-link {
    position: relative;
    width: 100%;
    border: 0 !important;
    cursor: pointer;

    &::after {
      content: "";
      position: absolute;
      top: 11px;
      right: 0;
      width: 10px;
      height: 6px;
      background: url("../public/images/land/downicon.png") center no-repeat;
      background-size: 100% 100%;
    }
  }
}

.payinfo {
  flex: 0 0 100%;
  width: 100%;
  font-size: 16px;
  color: #ffffff;
  line-height: 28px;

  img {
    width: 28px;
    height: 28px;
    margin-right: 8px;
  }
}

.payinfo1 {
  width: 285px;
  font-size: 16px;
  color: #ffffff;
  line-height: 28px;
  justify-content: space-between;

  img {
    width: 28px;
    height: 28px;
    margin-right: 8px;
  }

  .inputname {
    justify-content: flex-end;
  }
}

.inputimg {
  margin-right: 8px;

  img {
    width: 28px;
    height: 28px;

    &:last-child {
      margin-left: -14px;
    }
  }
}

.inputname {
  flex: 1;
  font-size: 16px;
  color: #ffffff;
  line-height: 28px;

  span {
    padding: 0 4px;
  }
}

.modal-con {
  position: relative;
  padding: 32px;
  width: 480px;

  .close {
    position: absolute;
    top: 32px;
    right: 32px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  .modal-title {
    margin-bottom: 32px;
    font-size: 20px;
    color: #ffffff;
  }

  .modal-desc {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.8);
  }

  .divestment-list {
    padding: 20px;
    border-radius: 8px;
    background: #242424;
    flex-wrap: wrap;

    .divestment-info {
      margin-bottom: 16px;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.6);
      line-height: 14px;

      &:last-child {
        margin-bottom: 0;
      }

      div {
        flex: 1;
      }

      p {
        flex: 0 0 40%;
        color: #fff;
        text-align: right;
      }
    }
  }

  .modal-tipsname {
    margin: 21px 0 11px;
    font-size: 14px;
    color: #fff;
    line-height: 16px;

    img {
      width: 16px;
      height: 16px;
      margin-right: 6px;
    }
  }

  .modal-tips1 {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.8);
    line-height: 14px;

    div {
      margin-bottom: 4px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .modal-input {
    margin: 20px 0 16px;
  }

  .modal-tips {
    font-size: 14px;
    color: #eb4343;
  }

  .modal-btncon {
    margin-top: 24px;
    height: 50px;
    background: url("../public/images/new/btnbg16.png") center no-repeat;
    background-size: 100% 100%;
    font-size: 16px;
    color: #000000;
    cursor: pointer;
  }

  .modal-btn {
    margin-top: 32px;
    justify-content: space-between;

    div {
      flex: 0 0 197px;
      height: 50px;
      background: url("../public/images/new/btnbg14.png") center no-repeat;
      background-size: 100% 100%;
      font-size: 18px;
      color: #ffffff;
      cursor: pointer;

      &:last-child {
        background: url("../public/images/new/btnbg15.png") center no-repeat;
        background-size: 100% 100%;
        color: #000000;
      }
    }
  }
  .forgottitle{
    margin-top: 24px;
    color: #c9fa5b;
    font-size: 16px;
    cursor: pointer;
    text-align: right;
  }
}

.h5top {
  display: none;
}

.sales-end {
  width: 98px;
  height: 80px;
  margin: 0 auto;
  background: url("../public/images/land/tagbg.png") center no-repeat;
  background-size: 100% 100%;
  font-size: 16px;
  color: #ffffff;
  z-index: 3;

  p {
    text-align: center;
    line-height: 80px;
    transform: rotate(-11deg);
  }
}

.endbg1 {
  background: url("../public/images/land/tagbg2.png") center no-repeat;
  background-size: 100% 100%;
}

.endbg2 {
  background: url("../public/images/land/tagbg1.png") center no-repeat;
  background-size: 100% 100%;
}

.endbg3 {
  background: url("../public/images/land/tagbg3.png") center no-repeat;
  background-size: 100% 100%;

  p {
    color: #000000;
  }
}
.ratecon {
  width: 816px;

  .rate-list {
    font-size: 16px;
    color: #FFFFFF;

    div {
      margin-bottom: 24px;
    }
  }

  .rate-table {
    border: 1px solid rgba(255, 255, 255, 0.08);
    box-sizing: border-box;

    .table-thead {
      background: rgba(201, 250, 91, 0.10);

      div {
        flex: 0 0 25%;
        height: 39px;
        font-size: 12px;
        color: rgba(255, 255, 255, 0.85);
        text-align: center;
        border-right: 1px solid rgba(255, 255, 255, 0.08);

        &:last-child {
          border-right: 0;
        }
      }
    }

    .tbody-info {
      border-top: 1px solid rgba(255, 255, 255, 0.08);

      div {
        flex: 0 0 25%;
        height: 39px;
        font-size: 12px;
        color: rgba(255, 255, 255, 0.85);
        text-align: center;
        border-right: 1px solid rgba(255, 255, 255, 0.08);
        line-height: 39px;

        &:last-child {
          border-right: 0;
        }
      }
    }
  }

  .ratebtn {
    width: 500px;
    height: 55px;
    margin: 24px auto 0;
    border-radius: 6px;
    background: #C9FA5B;
    font-size: 20px;
    color: #282626;
    font-weight: 600;
    cursor: pointer;
  }
}
@media (max-width: 1200px) {
  .nodewrappar {
    background: url("../public/images/land/h5bg1.png") center no-repeat;
    background-size: 100% 100%;

    .stardetails-bg {
      width: calc(100vw - 8px);
      flex-wrap: wrap;
      margin: 64px auto 0;
      background: none !important;

      .closeicon {
        position: absolute;
        top: 20px;
        left: inherit;
        right: 0;
      }

      .slider-left {
        flex: 0 0 100%;
        margin: 0;

        .left-name {
          margin-top: 0;
          width: 100%;

          &:after {
            width: 0;
            background: none !important;
          }
        }

        .marketbig-info {
          width: 100%;
          height: auto;
          margin: 0;
          background: none !important;

          .info-wrap {
            margin: 20px 0;
            padding: 20px 16px;
            border: 1px solid rgba(255, 255, 255, 0.1);
            box-sizing: border-box;
          }

          .info-name {
            padding: 0 0 16px 0;
            line-height: inherit;
          }

          .info-user {
            margin: 0;
            padding-bottom: 16px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
          }

          .info-subtitle {
            width: 100%;
            height: inherit;
            padding: 16px 0 0;
          }

          .info-list {
            width: 100%;
            margin-top: 0;
            padding: 20px 16px;
            border: 1px solid rgba(255, 255, 255, 0.1);
            box-sizing: border-box;

            .info-desc {
              flex: 0 0 33.3%;

              &:nth-child(-n + 3) {
                margin-bottom: 24px;
              }

              &:last-child {
                flex: 0 0 33.3%;
              }

              div {
                span {
                  display: inline-block;
                }
              }

              p {
                margin-top: 6px;
                font-size: 18px;
                line-height: 18px;

                span {
                  display: none;
                }
              }
            }
          }
        }

        .market-info {
          width: 100%;
          margin-top: 20px;
          padding: 20px 16px;
          border: 1px solid rgba(255, 255, 255, 0.1);
          box-sizing: border-box;
          background: none;

          .info-desc {
            flex: 0 0 33.3%;
            margin-bottom: 0;

            &:nth-child(-n + 4) {
              margin-bottom: 0;
            }

            &:nth-child(-n + 3) {
              margin-bottom: 24px;
            }

            &:last-child {
              flex: 0 0 33.3%;
            }

            div {
              span {
                display: inline-block;
              }
            }

            p {
              margin-top: 6px;
              font-size: 18px;
              line-height: 18px;

              span {
                display: none;
              }
            }
          }
        }
      }

      .slider-center {
        display: none;
        width: 100%;
        flex: 0 0 100%;
        width: 100%;
        margin-top: 12px;
        min-height: inherit;

        .center-top {
          width: 80px;
          height: 80px;

          img {
            width: 80px;
            height: 80px;
          }
        }

        .center-switch {
          top: 82px;
        }

        .center-bottom {
          width: 340px;
          height: 340px;
          margin: 0 auto;

          img {
            width: 340px;
            height: 340px;
          }
        }
      }

      .slider-right {
        flex: 0 0 100%;
        margin: 20px 0 0;

        .right-name {
          margin-bottom: 10px;
          font-size: 16px;
          line-height: 16px;
        }

        .right-tab {
          justify-content: flex-start;

          div {
            margin-right: 14px;
          }
        }

        .slider-btn {
          max-width: 320px;
          margin: 18px auto 0;
        }
      }

      .slider-right1 {
        flex: 0 0 100%;
        margin: 20px 0 0;
        padding: 0;
        background: none;

        .sales-end {
          display: none;
        }

        .land-list {
          padding: 20px 16px !important;
          border: 1px solid rgba(255, 255, 255, 0.1);
          box-sizing: border-box;

          .land-list-info {
            flex: 0 0 33.3%;
            margin-top: 24px;

            &:nth-child(-n + 3) {
              margin-top: 0;
            }

            p {
              margin-top: 6px;
              font-size: 18px;
              line-height: 18px;
            }
          }
        }

        .land-btn {
          margin-top: 24px;
        }
      }
    }
  }

  .modal-con {
    width: 100% !important;
    padding: 32px 16px;

    .modal-title {
      padding-right: 40px;
    }

    .close {
      right: 16px;
    }

    .modal-btn {
      div {
        flex: 0 0 49%;
        height: 44px;
      }
    }
  }

  .h5top {
    display: block !important;
  }

  .sales-end {
    position: absolute;
    top: 20px;
    left: 16px;
    width: 75px;
    height: 62px;

    p {
      line-height: 62px;
    }
  }

  .bg2 {
    background: url("../public/images/land/h5bg2.png") top no-repeat;
    background-size: cover;
  }

  .bg3 {
    background: url("../public/images/land/h5bg3.png") top no-repeat;
    background-size: cover;
  }

  .bg4 {
    background: url("../public/images/land/h5bg4.png") top no-repeat;
    background-size: cover;
  }

  .bg5 {
    background: url("../public/images/land/h5bg5.png") top no-repeat;
    background-size: cover;
  }

  .bg6 {
    background: url("../public/images/land/h5bg6.png") top no-repeat;
    background-size: cover;
  }

  .bg7 {
    background: url("../public/images/land/h5bg7.png") top no-repeat;
    background-size: cover;
  }
}

@media (max-width: 767px) {
  .nodewrappar {
    .stardetails-bg {
      margin: 0 auto;
    }
  }
}
</style>
